<template>
  <div class="scroll-container">
    <el-table
        :data="provider_list"
        ref="table"
        border
        show-header
        highlight-current-row
        size="mini"
        @sort-change='sortthiscolumn'
        :default-sort = "{prop: 'enable', order: 'descending'}"
    >
      <el-table-column  align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="编辑" placement="bottom-start">
            <el-button size="mini" plain @click="edit(scope.row)">
              <img :src="require('@/icons/svg/edit.svg')" >
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="人员列表" placement="bottom-start">
            <el-button size="mini" plain @click="personnel_list(scope.row)" :disabled="!scope.row.enable">
              <img :src="scope.row.enable?require('@/icons/svg/users.svg'):require('@/icons/svg/users-disabled.svg')" >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          sortable='custom'
          label="名称"
      >
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="areaName"
          label="管辖区域"
      >
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.areaName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="hierarchy"
          sortable='custom'
          label="层级"
          align="center"
      >
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.hierarchy }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center"  label="上级服务商" prop="pid" sortable='custom'>
        <template slot-scope="{row}">
            <span class="navbar-logo-link" style="cursor: pointer;color: #28afff" @click="set_pid(row.pid)">{{ (row.pid==0? '-':row.pname) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="customersnumber"
          sortable='custom'
          label="直属客户数量"
      >
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.customersnumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="first_contact"
          label="联系人"
      >
        <template slot-scope="{row}">
          <span :style="row.enable?'':'color: #d0d0d0'">{{ row.first_contact }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props:{
    sortthiscolumn: Function,
    provider_list: Array,
    edit: Function,
    personnel_list: Function,
    set_pid: Function,
  },
  data(){
    return {
    }
  },
  methods:{
  }
}
</script>

<style scoped>
.scroll-container {
  height: calc(100vh - 55px - 50px - 50px);
  overflow: auto;
}
</style>