<template>
  <div>
    <personnel_index :ret_index="retIndex" :provider_id="providerId" :show_personnel="showPersonnel"></personnel_index>
    <infor :select_all_supplier="selectAllSupplier" :region_list="regionList" :providers="providers" :provider_id="providerId" :form_data="formData" :show_edit="showEdit" :is_add="isAdd" :cancel_form="cancelForm"  ></infor>
    <div style="height: 50px;padding: 10px;">
      <div style="float: left;width: 40%;margin-left: 1%;margin-top: 8px;color: #808080">
        <span>服务商列表（{{ providerList.length }}条/共{{ count }}条）</span>
      </div>
      <div class="tools-bar" style="float: right; padding-right:20px; ">
        <el-select v-model="area" placeholder="区域" size="mini" style="width:11vw; padding-right: 0.7vw" clearable @change="selectAreaChange">
          <el-option
              v-for="item in regions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select v-model="pid" placeholder="上级服务商" size="mini" style="width:11vw; padding-right: 0.7vw" clearable @change="search">
          <el-option
              v-for="item in providers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input style="width:11vw; padding-right: 0.7vw;" size="mini" type="text" v-model="name" clearable placeholder="搜索" @keyup.enter.native="search" @clear="search"></el-input>
        <el-button style="margin-left: 0.7vw;" size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      </div>
    </div>
    <div style="clear: both;margin-left: 1%;width: 98%;height: calc(100vh - 55px - 50px - 50px);">
      <list :sortthiscolumn="sortthiscolumn" :set_pid="setPid" :personnel_list="personnelList" :provider_list="providerList" :edit="edit"></list>
    </div>
    <div class="bottom">
      <div style="float: left;margin-left: 35px;margin-top: 10px;padding-right: 35px;margin-right: 35px;">
        <el-button size="mini" type="primary" @click="insert">新增</el-button>
      </div>
      <div style="float: right;margin-right: 35px;margin-top: 1.2vh;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count">
        </el-pagination>
      </div>
    </div>


  </div>
</template>

<script>

import { getRegion,getSupRegion} from '@/api/area'
import { getList,selectSuperiorSupplier } from '@/api/supplier'
import list from './list'
import infor from './infor'
import personnel_index from "./personnel/personnelIndex"
export default {
  components: {
    list,
    infor,
    personnel_index,
  },
  data() {
    return {
      regions:[],
      pid:null,
      providers:[],
      column:{prop: 'enable', order: 'descending'},
      regionList: [],
      area:null,
      name:null,
      showPersonnel:false,
      providerId:0,
      showEquipment: false,
      isAdd: false,
      showEdit: false,
      formData:{

      },
      selectAll: false,
      pageNo: 1,
      pageSize: 10,
      count: 365,
      providerList:[],
    }
  },
  mounted() {
    this.getRegions()
    selectSuperiorSupplier().then(res => {
      this.providers = res.data.data
    })
    getRegion({pid:0}).then(res => {
      this.regionList = res.data.data
    })
    this.search()
  },
  methods:{
    selectAreaChange(){
      this.pid = null
      selectSuperiorSupplier({areaId:this.area}).then(res => {
        this.providers = res.data.data
      })
      this.search();
    },
    getRegions(){
      getSupRegion().then(res => {
        this.regions = res.data.data
      })
    },
    //排序方式
    sortthiscolumn(column){
      this.column=column
      this.search();
    },
    setPid(pid){
      this.pid = pid;
      this.search()
    },
    selectAllSupplier(){
      selectSuperiorSupplier().then(res => {
        this.providers = res.data.data
      })
    },
    retIndex(){
      this.showPersonnel = false
      this.showEquipment = false
    },
    handleCurrentChange(pageNo){
      this.pageNo = pageNo
      this.init()
    },
    handleSizeChange(size){
      this.setPageSizeIndex(size)
      this.search()
    },
    personnelList(row){
      this.showPersonnel=true
      this.providerId = row.id
    },
    edit(row){
      this.providerId = row.id
      this.isAdd = false;
      this.showEdit = true;
    },
    cancelForm(){
      this.showEdit = false;
      this.search();
    },
    insert(){
      this.showEdit = true;
      this.isAdd = true;
    },
    search(){
      this.pageNo = 1
      this.init()
    },
    init(){
      this.getRegions()
      this.pageSize=this.pageSizeIndex
      var list
      getList({
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        name:this.name,
        area:this.area,
        pid:this.pid,
        prop:this.column.prop,
        order:this.column.order,
      }).then(res => {
        list = res.data.data.list
        for(let i = 0; i < list.length; i++){
          for(let j = 0; j < this.regionList.length; j++){
            if(this.regionList[j].id == list[i].area){
              list[i].areaName = this.regionList[j].name
            }
          }
        }
        this.count=res.data.data.totalRow
        this.providerList = list
        this.selectAll = false
      })
    }
  }
}
</script>

<style scoped>
.bottom{
  position: absolute;
  width: 98%;
  height: 50px;
  bottom: 0;
  margin-left: 1%;
  box-shadow: 0px -3px 3px #c4c4c4;
}
</style>