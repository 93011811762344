<template>
  <div>
    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_edit" :before-close="cancel_form">
        <div style="position: absolute;width: 100%;top: 88vh">
          <el-button class="cancel" size="medium" style="margin-left:7%;width:19%;margin-right: 30%"  @click="cancel_form()">取消</el-button>
          <el-button  class="comfirm" size="medium" style="width: 35%;margin-right: 7%" type="primary" @click="submitForm()">保存</el-button>
        </div>
        <div style="padding-top:2.2vh;padding-left: 2.4vw;padding-right: 2.2vh;height:88vh;overflow: auto;">
          <div v-if="is_add" slot="header" style="height: 50px">
            <span><i class="el-icon-circle-plus-outline"></i> 添加</span>
          </div>
          <div v-else slot="header" style="height: 50px">
            <span><i class="el-icon-edit"></i> 编辑</span>
          </div>
          <el-form label-position="left" label-width="125px" size="large" :model="formData" ref="ruleForm" :rules="rules">
            <el-form-item label="名称:" prop="name">
              <el-input placeholder="请输入名称" type="text" v-model="formData.name" ></el-input>
            </el-form-item>
            <el-form-item label="上级服务商:" prop="pid">
              <el-select v-model="formData.pid" placeholder="服务商" size="mini" style="width:200px; padding-right: 10px" clearable >
                <el-option
                    v-for="item in providers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="区域:" prop="area">
              <el-select v-model="formData.area" placeholder="服务商" size="mini" style="width:200px; padding-right: 10px" clearable >
                <el-option
                    v-for="item in region_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址:" prop="address">
              <el-input placeholder="请输入地址" type="text" v-model="formData.address" ></el-input>
            </el-form-item>

            <el-form-item label="联系人:" prop="firstContact">
              <el-input placeholder="请输入联系人" type="text" v-model="formData.firstContact" ></el-input>
            </el-form-item>
            <el-form-item label="售前电话:" prop="pre_sale_tel">
              <el-input placeholder="请输入售前电话" type="text" v-model="formData.pre_sale_tel" ></el-input>
            </el-form-item>
            <el-form-item label="售后电话:" prop="after_sale_tel">
              <el-input placeholder="请输入售后电话" type="text" v-model="formData.after_sale_tel" ></el-input>
            </el-form-item>
            <el-form-item label="运维电话:" prop="ops_tel">
              <el-input placeholder="请输入运维电话" type="text" v-model="formData.ops_tel" ></el-input>
            </el-form-item>
            <el-form-item label="启用停用:" >
              <el-checkbox class="myRedCheckBox" v-model="formData.enable"  :label="(formData.enable?'已启用':'已禁用')"></el-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </transition>

  </div>


</template>

<script>
import { add,update,getOneById,selectAllSuppliers} from '@/api/supplier'
import { isMobile } from '@/utils/common'
export default {
  props:{
    region_list:Array,
    is_add: Boolean,
    cancel_form: Function,
    show_edit: Boolean,
    provider_id: Number,
    select_all_supplier: Function,
  },
  watch: {
    show_edit() {
      if (this.show_edit) {
        this.isLoad = false;
        if (this.is_add) {
          selectAllSuppliers().then(res => {
            this.providers = res.data.data
          })
          this.$refs.ruleForm.resetFields();
          this.formData.enable = false
        } else {
          getOneById({id:this.provider_id}).then(res => {
            this.providers = res.data.data.providers
            this.providers.splice(0, 0, {
              id:0,
              name:'-',
              enable:true
            });
            this.$refs.ruleForm.resetFields();
            this.formData.name = res.data.data.name //名称
            this.formData.pid = res.data.data.pid //上级服务商
            this.formData.area = res.data.data.area //区域
            this.formData.address = res.data.data.address //地址
            this.formData.firstContact = res.data.data.first_contact
            this.formData.enable = res.data.data.enable
            this.formData.pre_sale_tel = res.data.data.pre_sale_tel
            this.formData.after_sale_tel = res.data.data.after_sale_tel
            this.formData.ops_tel = res.data.data.ops_tel

          })
        }
      }
    },
  },
  data(){
    const regTle = /^(((\d{3,4}-)?[0-9]{7,8})|(1[3584]\d{9}))$/;
    var checkPreSaleTel = (rule,value,callback) =>{
      if (!value) {
        return callback(new Error('售前电话不能为空'))
      } else {
        if (regTle.test(value) == false) {
          return callback(new Error('售前电话格式不正确'))
        }
        callback()
      }
    }
    var checkAfterSaleTel = (rule,value,callback) =>{
      if (!value) {
        return callback(new Error('售后电话不能为空'))
      } else {
        if (regTle.test(value) == false) {
          return callback(new Error('售后电话格式不正确'))
        }
        callback()
      }
    }
    var checkOpsTel = (rule,value,callback) =>{
      if (!value) {
        return callback(new Error('运维电话不能为空'))
      } else {
        if (regTle.test(value) == false) {
          return callback(new Error('运维电话格式不正确'))
        }
        callback()
      }
    }
    return {
      providers:[],
      isLoad:false,
      formData: {
        name:'',//名称
        pid:'',//上级服务商
        area:'',//区域
        address:'',//地址
        enable:false,
        firstContact:'',//联系人姓名
        pre_sale_tel:null,//售前电话
        after_sale_tel:null,//售后电话
        ops_tel:null//运维电话
      },
      rules:{
        name:[
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        area:[
          { required: true, message: '区域不能为空', trigger: 'change' },
        ],
        address:[
          { required: true, message: '地址不能为空', trigger: 'blur' },
        ],
        firstContact:[
          { required: true, message: '联系人不能为空', trigger: 'blur' },
        ],
        pre_sale_tel:[
          { required: true, validator:checkPreSaleTel, trigger: 'blur' },
        ],
        after_sale_tel:[
          { required: true,validator:checkAfterSaleTel, trigger: 'blur' },
        ],
        ops_tel:[
          { required: true,validator:checkOpsTel, trigger: 'blur' },
        ],
        drawerWidth:'35%'
      },
    }
  },
  mounted() {
    if (isMobile()) {
      this.drawerWidth = '55%'
    } else {
      this.drawerWidth = '35%'
    }
  },
  methods: {
    submitForm(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.is_add){
            add(this.formData).then(res => {
              if(res.data && res.data.state=="ok"){
                this.$message.success(res.data.msg ? res.data.msg :'保存成功')
                this.select_all_supplier()
                this.cancel_form();
              }else{
                this.$message.error(res.data.msg ? res.data.msg :'保存失败')
              }
            })
          }else {
            this.formData.id = this.provider_id
            update(this.formData).then(res => {
              if(res.data && res.data.state=="ok"){
                this.$message.success(res.data.msg ? res.data.msg :'保存成功')
                this.select_all_supplier()
                this.cancel_form();
              }else{
                this.$message.error(res.data.msg ? res.data.msg :'保存失败')
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
<style>
.myRedCheckBox .el-checkbox__input+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0f9f00;
}

.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner, .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0f9f00;
}
.myRedCheckBox .el-checkbox__input .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner{
  border-color: #0f9f00;
}
</style>