<template>
  <div>
    <transition name = "fade">
      <el-drawer :size="drawerWidth" :with-header="false" :visible.sync="show_edit" :before-close="cancelForm">
        <div style="position: absolute;width: 100%;top: 88vh">
          <el-button class="cancel" size="medium" style="margin-left:7%;width:19%;margin-right: 30%"  @click="cancelForm();">取消</el-button>
          <el-button  class="comfirm" size="medium" style="width: 35%;margin-right: 7%" type="primary" @click="submitForm()">保存</el-button>
        </div>
        <div style="padding-top:2.2vh;padding-left: 2.4vw;padding-right: 2.2vh;height:88vh;overflow: auto;">
          <div v-if="add" slot="header" style="height: 50px">
            <span><i class="el-icon-circle-plus-outline"></i> 添加</span>
          </div>
          <div v-else slot="header" style="height: 50px">
            <span><i class="el-icon-edit"></i> 编辑</span>
          </div>



          <el-form label-position="left" label-width="125px" size="large" :model="formData" ref="ruleForm" :rules="rules">
            <el-form-item label="姓名:" prop="name">
              <el-input placeholder="请输入姓名" type="text" v-model="formData.name" ></el-input>
            </el-form-item>
            <el-form-item label="账号:" prop="username">
              <el-input placeholder="请输入账号" type="text" v-model="formData.username" ></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password">
              <el-input :placeholder="add?'请输入密码':'请输入新密码；为空不修改'" type="password" v-model="formData.password" ></el-input>
            </el-form-item>
            <el-form-item label="联系方式:" prop="mobile_phone">
              <el-input placeholder="请输入联系方式" type="text" v-model="formData.mobile_phone" ></el-input>
            </el-form-item>
            <el-form-item label="启用停用:" >
              <el-checkbox class="myRedCheckBox" v-model="formData.enable"  :label="(formData.enable?'已启用':'已禁用')"></el-checkbox>
            </el-form-item>
            <el-form-item v-if="!is_add" label="绑定微信:">
              <img :src="'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+formData.qrcode" style="margin-left: 10px;margin-right: 10px;width: 20vh">
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </transition>

  </div>


</template>

<script>
import {addSupUser,updateSupUser,getOneById} from '@/api/user'
import {Encrypt} from '@/utils/secert'
import { isMobile } from '@/utils/common'
export default {
  props:{
    is_add: Boolean,
    cancel_form: Function,
    show_edit: Boolean,
    storage_list: Array,
    per_id:Number,
    provider_id:Number,
  },
  watch:{
    show_edit(){
      let _this = this
      if(this.show_edit){
        this.add = this.is_add
        if(this.is_add){
          _this.formData.enable=true
          _this.formData.password = null
          _this.formData.qrcode=null
          this.formData.password = null
          this.$nextTick(() => {
            this.$refs.ruleForm.resetFields();
          })
        }else{
          this.$nextTick(() => {
            getOneById({id:this.per_id}).then(res => {
              _this.$refs.ruleForm.resetFields();
              this.formData.name=res.data.data.name;//姓名
              this.formData.username=res.data.data.username;//账号
              this.formData.password=res.data.data.password;//密码
              this.formData.mobile_phone=res.data.data.mobile_phone;//电话
              this.formData.enable=res.data.data.enable
              this.formData.qrcode=res.data.data.qrcode
            })
          })
        }
      }
    }
  },
  data(){
    return {
      add:false,
      formData: {
        enable:false,
        id:null,
        name:'',//姓名
        username:null,//账号
        password:null,//密码
        mobile_phone:null,//电话
        qrcode:null,
      },
      rules:{
        name:[
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        username:[
          { required: true, message:'账号不能为空', trigger: 'blur' }
        ],
        password:[
          { required: true, validator:this.checkPassward, trigger: 'blur' }
        ],
        mobile_phone:[
          { required: true, validator:this.checkPhone, trigger: 'blur' },
        ],
      },
      drawerWidth:'68%'
    }
  },
  mounted() {
    if (isMobile()) {
      this.drawerWidth = '55%'
    } else {
      this.drawerWidth = '38%'
    }
  },
  methods: {
    checkPassward(rule,value,callback){
      if(this.add){
        if (value === null || value.trim() === '') {
          callback(new Error('密码不能为空'))
        } else {
          callback()
        }
      }else{
        callback()
      }
    },
    checkPhone(rule,value,callback){
      let reg = /^1[34578]\d{9}$/
      if (value === null || value.trim() === '') {
        callback(new Error('联系方式不能为空'))
      } else {
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('联系方式格式不正确'))
        }
      }
    },
    cancelForm(){

      this.cancel_form();
    },
    submitForm(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.formData.supplierId = this.provider_id
          var password = this.formData.password
          if(this.formData.password){
            this.formData.password = Encrypt(this.formData.password)
          }
          if(this.is_add){
            addSupUser(this.formData).then(res => {
              if(res.data && res.data.state=="ok"){
                this.$message.success(res.data.msg ? res.data.msg :'保存成功')
                this.cancel_form();
              }else{
                this.$message.error(res.data.msg ? res.data.msg :'保存失败')
              }
            })
          }else {
            this.formData.id = this.per_id
            updateSupUser(this.formData).then(res => {
              if(res.data && res.data.state=="ok"){
                this.$message.success(res.data.msg ? res.data.msg :'保存成功')
                this.cancel_form();
              }else{
                this.$message.error(res.data.msg ? res.data.msg :'保存失败')
              }
            })
          }
          this.formData.password = password
        }
      })
    },
  },
}
</script>

<style scoped>
.edit-card{
  box-shadow: -0px 0px 20px #3e3e3e;
  position: absolute;
  right: 0;
  width: 35%;
  min-width: 400px;
  height: 100%;
  z-index:998;
  overflow-y: auto;
}
</style>
<style>
.myRedCheckBox .el-checkbox__input+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus+.el-checkbox__label{
  color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0f9f00;
}

.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner, .myRedCheckBox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0f9f00;
}
.myRedCheckBox .el-checkbox__input .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #ff0000;
}
.myRedCheckBox .el-checkbox__input.is-checked .el-checkbox__inner{
  border-color: #0f9f00;
}
</style>